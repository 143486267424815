import * as React from "react";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF", "#3d00e6"];
const words= ["Home", "About Us", "Pricing", "Contact", "Get Started"];
const links = ["/", "/about", "/pricing", "/contact", "/get-started"]

export const MenuItem = ({ i }) => {
  const style = { border: `2px solid ${colors[i]}` };
  const text = `${words[i]}`;
  const link = `${links[i]}`;
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
<Link to={link} activeClassName="active">
      <div className="text-placeholder" style={style} >
            
             {text}
            
            <style jsx="">
            {
              `
              .text-placeholder .active {
                font-weight: bold;
                color: black;
              
              `
            }
            </style>
      </div>
      </Link>
    </motion.li>
  );
};
