import React from 'react';
import Fade from "react-reveal/Fade";
import NYC from '../images/nyc.png'
import Button from './Button'
// import JobDescriptions from './JobDescriptions'

const CareersForm = () => {
    return (
        <div>
            <div className="careers-hero">
                <div className="careers-hero-title">
                    <Fade down>
                        <h1>Work With Us</h1>
                    </Fade>
                </div>
            </div>
            <br/>
            <br/>
            {/* <JobDescriptions/> */}
            <div className="careers-form">
            {/* <JobDescriptions/> */}
                <h4>
                    If you are interested in working with threeTheorem, please fill out the form below.
                </h4>
            <form className="careers"                 
                method="POST"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                netlify = "true"
                enctype="multipart/form-data"
                >
                <input type="hidden" name="form-name" value="careers" />

                <Fade left>
                <input 
                    className="input-style" 
                    type="text" 
                    name="name" 
                    placeholder="Name*" 
                    required />
                <br/>
                <br/>
                <input 
                    className="input-style" 
                    type="email" 
                    name="email" 
                    placeholder="Email*"  
                    required />
                <br/>
                <br/>
                <select 
                    className="input-style select-style" 
                    name="role[]">
                    <option value="position" disabled>Position interested:</option>
                    <option value="Product Manager">Project Manager</option>
                    <option value="Software Engineer">Software Engineer</option>
                    <option value="Solutions Architect">Solutions Architect</option>
                    <option value="Web Designer">Web Designer</option>
                    <option value="Backend Developer">Backend Developer</option>
                    <option value="Frontend Developer">Frontend Developer</option>
                    <option value="Mobile Developer">Mobile Developer</option>
                    <option value="QA Engineer">QA Engineer</option>
                    <option value="Mobile Developer">Database Administrator</option>
                </select>
                <br/>
                <br/>
                <input 
                    className="input-style" 
                    type="text" 
                    name="experience" 
                    placeholder="How many years of experience (Position interested)?*"  
                    required/>
                <br/>
                <br/>
                <textarea 
                    type="text" 
                    className="input-style textarea-style" 
                    name="whyDoYouWantToWorkHere" 
                    placeholder="Why do you want to work for threeTheorem?*" 
                    required>
                </textarea>
                </Fade>
                <br/>
                <br/>
                <div className="upload">
                    <span>Resume Upload*:</span> 
                    <input 
                        className="file-style" 
                        type="file" 
                        name="file" />
                </div>
                <br/>
                <br/>
                <Fade right>
                <Button type="submit">
                    <p className="submit">Submit</p>
                  </Button>
                </Fade>
            </form>
            </div>
            <style jsx>
            {
                `
                .careers-hero {
                    height: 60vh;
                    background:linear-gradient(
                        rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7)), url(${NYC}) center center no-repeat;
                      background-size: cover;
                      
                }
                .careers-hero-title {
                    position: absolute;
                    left: 50%;
                    top: 30vh;
                    transform: translate(-50%, -50%);
                    color: white;
                    text-align: center;
                    width: 100%;
                }
                .careers-form {
                    width: 50%;
                    padding: 40px 0;
                    margin: auto;
                    padding-bottom: 120px;
                }
                .input-style {
                    margin: 0 auto;
                    width: 100%;
                    height: 35px;
                    border-radius: 10px;
                    border: 3px solid #7700ff;
                    outline: none;
                    font-size: 18px;
                    padding: 0 0 0 10px;
                    font-family: "Open Sans Condensed", sans-serif;
                    color: #212121;
                    box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.2);
                }
      
                .upload span {
                    font-weight: bold;
                    
                }
                .file-style {
                    color: #212121;
                    font-family: "Open Sans Condensed", sans-serif;
                    font-size: 16px;
                    display: flex;
                    
                }
                .textarea-style {
                    padding: 10px 0 0 10px;
                    height: 100px;
                }
                .careers button {
                    float: left !important;
                    width: 40%;
                  
                 }
                 @media screen and (max-width: 600px) {
                    .careers-form {
                      width: 80%;
                    }

                    .input-style {
                      padding: 0px 4px;
                      margin: 0 auto;
                      width: 95%;
                    }

                    .careers button {
                      width: 100%;
                    }
                  }
                `
            }
            </style>
        </div>
    );
};

export default CareersForm;