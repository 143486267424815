import React, { useEffect } from 'react';
import AboutContent from '../components/AboutContent'

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    
    return (
        <div>
            <AboutContent/>
        </div>
    );
};

export default About;