import React, { useEffect } from 'react';
import CareerForms from '../components/CareersForm'

const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className="career">
            <CareerForms/>
        </div>
    );
};

export default Careers;