import React from "react";
import Button from "./Button";
import Fade from "react-reveal/Fade";
import NYC from "../images/nyc.png";

const GetStartedForm = () => {

  return (
    <div className="get-started">
      <div className="get-started-hero">
        <div className="get-started-hero-title">
          <Fade down>
            <h1>Get Started</h1>
          </Fade>
        </div>
      </div>
     
        <div className="get-started-form">
          <h4>
            Thank you for your interest in threeTheorem. Complete the form to
            start building your application.
          </h4>
          <form
            name="getStartedForm"
            method="POST"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            netlify = "true"
            data-netlify-recaptcha="true"
          >
            <input type="hidden" name="form-name" value="getStartedForm" />

            <Fade left>
              <input
                className="name"
                type="text"
                placeholder="Name*"
                name="name"
                required
              />
              <br />
              <br />
              <input
                className="email"
                type="text"
                placeholder="Email*"
                name="email"
                required
              />
              <br />
              <br />
              <textarea
                className="about-app"
                type="text"
                name="message"
                placeholder="Tell us a little bit about the app you would like to build.*"
                required
              ></textarea>
              <br />
              <br />
              <input
                className="company"
                type="text"
                name="company"
                placeholder="Company Name"
                required
              />
              <br />
              <br />
              <input
                className="title"
                name="jobTitle"
                type="text"
                placeholder="Job Title"
              />
              <br />
              <br />
              <input
                className="hear-about-us"
                type="text"
                name="hearAboutUs"
                placeholder="Where did you hear about us?"
              />
            </Fade>
            <br />
            <br />
            <Fade right>
              <Button type="submit">
                <p>Submit</p>
              </Button>
            </Fade>
          </form>
        </div>
      
      <style jsx="">
        {`
          .get-started {
            height: auto;
            margin: 0 auto;
            margin-bottom: 70px;
          }
          .get-started-form h4 {
            margin: 20px 0 30px;
          }
          .get-started button {
            width: 40%;
            float: left;
          }
          .get-started-hero {
            height: 60vh;
            background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7)),
              url(${NYC}) center center no-repeat;
            background-size: cover;
          }
          .get-started-hero-title {
            position: absolute;
            left: 50%;
            top: 30vh;
            transform: translate(-50%, -50%);
            color: white;
            text-align: center;
            width: 100%;
          }
          .get-started-form {
            width: 50%;
            padding: 40px 0;
            margin: auto;
          }
          .get-started-form .name,
          .get-started-form .email,
          .get-started-form .company,
          .get-started-form .title,
          .get-started-form .hear-about-us {
            margin: 0 auto;
            width: 100%;
            height: 35px;
            border-radius: 10px;
            border: 3px solid #7700ff;
            outline: none;
            font-size: 18px;
            padding: 0 0 0 10px;
            font-family: "Open Sans Condensed", sans-serif;
            color: #212121;
            box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.2);
          }
          .get-started-form .name {
            border: 3px solid #7700ff;
          }
          .get-started-form .email {
            border: 3px solid #7700ff;
          }
          .get-started-form .about-app {
            border: 3px solid #9c1aff;
            margin: 0 auto;
            width: 100%;
            border-radius: 10px;
            border: 3px solid #7700ff;
            outline: none;
            font-size: 18px;
            padding: 10px 0 0 10px;
            font-family: "Open Sans Condensed", sans-serif;
            color: #212121;
            height: 100px;
            box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.2);
          }
          .get-started-form .company {
            border: 3px solid #7700ff;
          }
          .get-started-form .title {
            border: 3px solid #7700ff;
          }
          .get-started-form .hear-about-us {
            border: 3px solid #7700ff;
          }
          @media screen and (max-width: 600px) {
            .get-started-form {
              width: 80%;
            }
            .get-started-form h4 {
              margin: 20px 0 30px;
              font-weight: normal;
              // text-align: left;
              font-size: 24px;
            }
            .get-started-form .name,
            .get-started-form .email,
            .get-started-form .company,
            .get-started-form .title,
            .get-started-form .hear-about-us {
              padding: 0px 4px;
              margin: 0 auto;
              width: 95%;
            }
            .get-started-form .about-app {
              padding: 4px 4px;
              margin: 0 auto;
              width: 95%;
            }

            .get-started-form button {
              width: 100%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default GetStartedForm;
