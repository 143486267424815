import React, { useEffect } from "react";

export default function Success() {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <div className="success">
      <h4>Thank you, your form has been submitted!</h4>
      <style jsx="">
      {
        `
        .success {
          padding: 0px 20px;
          background: #212121;
          height: 100vh;
          color: white;
          font-weight: bold;
          text-align: center;
      }
      .success h4 {
          position: relative;
          padding-top: 220px;
          color: white;
          text-align: center;
          margin: 0;
      }
        `
      }
      </style>
    </div>
  );
}
