import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom'
import { motion } from "framer-motion";
import useWindowScrollPosition from "@rehooks/window-scroll-position"

import Logo from '../images/3t-white.png'

const Nav = () => {
    const [change, setChange] = useState(false);
    const changePosition = 140;

    let position = useWindowScrollPosition();

    if (position.y > changePosition && !change) {
        setChange(true);
      }
    
    if (position.y <= changePosition && change) {
        setChange(false);
        }
        

    let style = {
            // background: change ? "#232526" : "transparent",
            // background: change ? "-webkit-linear-gradient(to right, #7700FF, #4400FF)" : "transparent",
            background: change ? "linear-gradient(to right, #7700FF, #4400FF)" : "transparent",
            padding: change ? "10px 0" : "40px 0",
            // height: change ? "75px" : "auto",
            transition: "300ms ease"
        
        };

    let imgStyle = {
        width: change ? "60px" : "90px",
        height: change ? "auto" : "auto",
        position: "relative",
        top: change ? "0" : "0",
        transition: "400ms ease"
    }

    let title = {
        opacity: change ? "1" : "0",
        transition: "500ms ease"
    }

    return (
        <>
        <motion.div className="nav-wide nav-wrapper nav-wrapper-3"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{ ease: "easeOut", duration: 1 }}   
            style={style}         
            >
            <div className="threetheorem-container">
                <Link to="/">
                    <img className="threetheorem-logo" alt="logo" src={Logo} style={imgStyle}/> <span style={title} className="threetheorem-name">threeTheorem</span>
                </Link>
            <span className="effect-3">
                <ul>
                    <li>
                        <NavLink exact to="/" 
                            activeStyle={{

                                color: "#39e1ff"
                            }}>
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/about"
                            activeStyle={{
                            color: "#39e1ff"
                          }}>
                            About Us
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/work">
                            Our Work
                        </NavLink>
                    </li> */}
                    <li>
                        <NavLink exact to="/pricing"
                            activeStyle={{
                            color: "#39e1ff"
                          }}>
                            Pricing
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/blog">
                            Blog
                        </NavLink>
                    </li> */}
                    <li>
                        <NavLink exact to="/contact"
                            activeStyle={{
                            color: "#39e1ff"
                          }}>
                            Contact
                        </NavLink>
                    </li>
                    <li className="button">
                        <NavLink exact to="/get-started"
                            activeStyle={{
                            color: "#39e1ff"
                          }}>
                            Get Started
                        </NavLink>
                    </li>
                </ul>
                </span>
            </div>
        </motion.div>
        <style jsx="">{
                `
                .threetheorem-logo {
                    height: 90px;
                    width: auto;
                    float: left;
                    filter:drop-shadow(7px 7px 7px #666666); 
                    -webkit-filter:drop-shadow(7px 7px 7px #666666); 
                }
                .threetheorem-name {
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 0.1rem;
                    color: white;
                    position: relative;
                    top: 9px;
                    left: 10px;
                    text-transform: none;
                }
                .nav-wide {
                    width: 100%;
                    position: fixed;
                    padding: 40px 0;
                    z-index: 9999;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                .nav-wide ul {
                    float: right;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .nav-wide ul li {
                    float: left;
                    display: flex;
                    margin: 0 30px;
                }
                a {
                    text-decoration: none;
                    color: #fff;
                    font-size: 15px;
                }
                // .nav-wide .button {
                    
                    
                  
                //     box-shadow: 0 20px 30px -14px rgba(0,0,0,0.75);
                // }
                ul {
                    list-style-type: none;
                    position: relative;
                  }
                  
                  li {
                  
                    position: relative;
                  }
                  
                  a {
                    text-decoration: none;
                    color: #fff;
                    text-transform: uppercase;
                  }
                .effect-3 a:before,
                .effect-3 a:after {
                    content: "";
                    height: 3px;
                    width: 0;
                    opacity: 0;
                    background-color: #fff;
                    position: absolute;
                    transition: all .5s;
                }

                .effect-3 a:before {
                    top: -3px;
                }

                .effect-3 a:after {
                    bottom: -3px;
                    right: 0;
                }

                .effect-3 a:hover:before,
                .effect-3 a:hover:after {
                    width: calc(100% + 20px);
                    opacity: 0.7;
                }
                @media screen and (max-width: 600px) {
                    .nav-wide {
                        display: none;
                    }  
                  }
                `
            }
            </style>
        </>
    );
};

export default Nav;