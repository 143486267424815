import React from 'react';
import Routes from './components/routes'
import Nav from './components/Nav'
import {BrowserRouter as Router} from 'react-router-dom'
import { NavMobile } from "./components/NavMobile/NavMobile";
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
        <Router>
          <Nav/>
          <NavMobile/>
          <Routes/>
          <Footer/>
        </Router>
        <style jsx global="true">
          {
            `
            @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap');
            
            html, body {
              overflow-x: hidden !important;
              margin: 0;
              font-family: 'Open Sans Condensed', sans-serif;
            }
            .threetheorem-container {
              padding: 0 100px;
            }
            h1 {
              font-size: 100px;
              margin: 0;
             
            }
            h4 {
              font-size: 24px;
              margin: 30px 0 60px;
              text-align: left;
            }
            button {
              outline: none;
            }

            @media screen and (max-width: 600px) {
              h1 {
                  font-size: 50px;
              } 
              h4 {
                font-size: 17px;
                text-align: center;
              } 
            }
          `}
        </style>
    </div>
  );
}

export default App;
