import React from 'react';
import Fade from 'react-reveal/Fade';

const Review = () => {
    return (
        <div className="review">
        <Fade left>
            <div className="left-section mobile">
                <div className="content">
                    <h3>Why people 
                        <br/>
                        recommend us?</h3>
                </div>
            </div>
            </Fade>
            <Fade right>
            <div className="right-section mobile">
                <div className="content">
                    <p>“We were looking for something we didn't think we'd find: High-quality, flexible and
                        scalable dev support. We were stunned that we were able to get all three in threeTheorem.”</p>
                    <p className="owner-name"><i>– Mymy, owner of Makefish and Delilah's</i></p>
                </div>
            </div>
            </Fade>
        <style jsx="">
        {
            `
            .review {
                position: relative;
                height: 50vh;
                background: -webkit-linear-gradient(to right, #7700FF, #4400FF);
                background: linear-gradient(to right, #7700FF, #4400FF);
                color: white;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                z-index: 0;
            }
            .left-section {
                width: 30vw;
                text-align: center;
                float: left;
                border-top-right-radius: 60%;
                border-bottom-right-radius: 60%;
                border-right: 40px solid #212121;
                height: 50vh;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                background: white;
                color: #212121 !important;
                // box-shadow: 0 20px 50px 10px rgba(0,0,0,0.5);
                
            }
            .left-section .content h3 {
               margin: 0;
               font-size: 40px;
            }
            .right-section {
                width: 70vw;
                float: right;
                text-align: center;
                padding: 0 100px;
            }
            .right-section .content p {
                font-size: 24px;
                padding-right: 50px;
            }
            .right-section .content .owner-name {
                color: #39e1ff;
                font-weight: bold;
            }
            @media screen and (max-width: 600px) {
                .review {
                    float: left;
                    height: auto;
                    display: block;
                    padding: 50px 0 60px;
                }
                .left-section {
                    width: 100%;
                    float: none;
                    position: relative;
                    display: block;
                    height: auto;
                    background: none;
                    color: white !important;
                    border-color: white;
                    box-shadow: none;
                    border-radius: 0%;
                    border: none;
                }
                .left-section .content h3 {
                    padding-top: 30px;
                }
                .right-section {
                    position: relative;
                    width: 90%;
                    float: none;
                    display: block;
                    margin-left: 11%;
                    padding: 0;

                }
            }
            `
        }
        </style>
        </div>
    );
};

export default Review;