import React from 'react';

const Button = (props) => {
    let className = ''

    className += props.fullWidth ? ' full-width' : ''

    return (
        <div>
            <button className={className}>
                {props.children}
            </button>  
            <style jsx="">
                {
                    `
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        appearance: none;
                        padding: 0.2rem 3.5rem;
                        color: #fff;
                        cursor: pointer;
                        font-size: 0.9rem;
                        border-radius: 1em;
                        text-transform: uppercase;
                        transition: all 0.2s ease;
                        margin: auto;
                        background: #232526;
                        background: -webkit-linear-gradient(to right, #4400FF, #7700FF);
                        background: linear-gradient(to right, #4400FF, #7700FF);
                        box-shadow: 0 20px 30px -14px rgba(0,0,0,0.75);
                        border: 0px;
                    }
                    
                    button:hover {
                        box-shadow: 0 20px 50px -14px rgba(0,0,0,0.75);
                        padding: 8px 70px;
                      }
                 
                    .full-width {
                        width: 100%;
                        text-align: center;
                    }
                    @media screen and (max-width: 600px) {
                      button {
                          width: 90%;
                          margin: auto;
                          padding: 0 20px;

                      }  
                    }
                    `
                }
                </style>  
        </div>
    );
};

export default Button;