import React from 'react';
import Fade from 'react-reveal/Fade';
import Laptop from '../images/laptop.jpg'
import Design from '../images/design.svg'
import Maintenance from '../images/repair.svg'
import Upload from '../images/upload.svg'
import Price from '../images/price.svg'
import NYC from '../images/nyc.png'

const PricingContent = () => {
    return (
        <div>
            <div className="pricing-hero">
                <div className="pricing-hero-title">
                <Fade  down>
                    <h1>Easy Pricing</h1>
                </Fade>
                </div>
            </div>
            <div className="pricing-info">
                <p>
                threeTheorem Pro is the best way to get a custom web application or mobile application without any hidden costs or initial fees. 
                To get started you will need to submit the first month's payment and from there we will begin building your new application. Your monthly subscription includes everything listed below and so much more:
                </p>
            </div>
            <div className="pricing">
                <Fade left>
                <span className="col-3">
                    <img className="price-icon" src={Design} alt="" />
                    <h4>Design &#8226; Plan &#8226; Code</h4>
                    <p>&#8226; Mobile, Desktop, Tablet</p>
                    <p>&#8226; Meetings</p>
                    <p>&#8226; Mockups, Wireframes, Sketches</p>
                    <p>&#8226; Execution</p>
                    <p>&#8226; Testing, Code Reviews</p>

                </span>
                <span className="col-3 col-border">
                <img className="price-icon" src={Upload} alt="" />
                <h4>Deployment</h4>
                <p>
                    &#8226; AWS host
                </p>
                <p>&#8226; Heroku host
                </p>
                <p>&#8226; Google Cloud host
                    </p>
                <p>
                    &#8226; Azure host
                </p>
                <p>
                    &#8226; Digital Ocean host
                    </p>
                <p>
                    &#8226; Development server
                </p>
                <p>
                    &#8226; Staging server
                </p>
                <p>
                    &#8226; Production Server
                </p>
                
                <p>&#8226; SSL Certificate (web security)</p>
                </span>
                <span className="col-3 col-border-2">
                    <img className="price-icon" src={Maintenance} alt="" />
                    <h4>Maintanence</h4>
                    <p>&#8226; Update content</p>
                    <p>&#8226; Update images</p>
                    <p>&#8226; Added features</p>
                    <p>&#8226; Security</p>
                    <p>&#8226; Software updates</p>
                    <p>&#8226; Debug</p>
                    <p>&#8226; More</p>
                </span>
                <span className="col-3">
                    <img className="price-icon" src={Price} alt="" />
                    <h4>Pricing</h4>

                    <p className="price-text">Starting at:</p>
<h4>$199 per month

</h4>
+
<h4>
    $75 / hour
</h4>
<p className="price-text">*Pricing restrictions apply</p>
                </span>
                </Fade>
            </div>
            <style jsx="">
            {
                `
                .pricing-hero {
                    height: 60vh;
                    background:linear-gradient(
                        rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7)), url(${NYC}) center center no-repeat;
                    background-size: cover;
                }
                .pricing-info {
                    padding: 60px 50px;
                    font-size: 24px;
            
                }
                .pricing-info p {
                    width: 50%;
                    margin: auto;
                }
                .pricing {
                    height: 70vh;
                    background:linear-gradient(
                        to right, rgba(119, 0, 255, 0.7), rgba(68, 0, 255, 1)), url('${Laptop}') center center no-repeat;
                    background-size: cover;
                    background-attachment: fixed;
                   display: flex;
                   justify-content: space-between;
                   align-items: center;
                   color: white;
                   padding: 50px 0;
                   text-align: center;
                //    border-top-left-radius: 5%;
                //    border-top-right-radius: 5%;
                }
                .pricing .price-icon {
                  
                    margin-bottom: 10px;
                    width: 50px;
                    height: auto;
                    
                }
                .pricing .col-3 {
                    width: 25%;
                    height: 70vh;
                    padding: 50px 0;
                }
                .pricing .col-3 h4 {
                    margin: 0;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 22px;
                }
                .pricing .col-3 p {
                    text-align: left;
                    font-size: 18px;
                    padding: 0 50px;
                }
                .pricing .col-3 .price-text {
                    text-align: center;
                }
                .pricing .col-border {
                    border-left: 2px solid white;
                    border-right: 2px solid white;
                }
                .pricing .col-border-2 {
                    border-right: 2px solid white;
                }
                .pricing-hero-title {
                    position: absolute;
                    left: 50%;
                    top: 30vh;
                    transform: translate(-50%, -50%);
                    color: white;
                    text-align: center;
                    width: 100%;
                }
                @media screen and (max-width: 600px) {
                    .pricing-hero-title {
                        top: 25vh !important;
                    }
                    .pricing-info p {
                        width: 90%;
                    }
                    .pricing {
                        padding: 0;
                        display: block;
                        height: auto;

                    }
                    .pricing .col-3 {
                        padding: 50px 0;
                        width: 100%;
                        display: block;
                        height: auto;
                        text-align: center;
                     
                    }
                    .pricing .col-border {
                        border: none;
                        border-top: 1px solid white;
                        border-bottom: 1px solid white;
                    }
                    .pricing .col-border-2 {
                        border: none;
                        border-bottom: 1px solid white;
                    }
                }
                `
            }
            </style>
        </div>
    );
};

export default PricingContent;