import React, { useEffect } from 'react';
import Hero from '../components/Hero'
import MainSection from '../components/MainSection'
import MainSectionTwo from '../components/MainSectionTwo'
import MainSectionThree from '../components/MainSectionThree'
import Review from '../components/Review'
import NYBorder from '../components/NYBorder'

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div
        ><NYBorder/>
            <Hero/>
            
            <MainSection/>
            <MainSectionTwo/>
            <br/>
            <br/>
            <MainSectionThree/>
            <Review/>
            
        </div>
    );
};

export default Home;