import React, { useState } from 'react';
import Button from '../components/Button'
import { motion } from "framer-motion";
import useWindowScrollPosition from "@rehooks/window-scroll-position"
import { Link } from 'react-router-dom'
import NYC from '../images/nyc.png'

const Hero = () => {
    const [change, setChange] = useState(false);
    const changePosition = 130;

    let position = useWindowScrollPosition();

    if (position.y > changePosition && !change) {
        setChange(true);
      }
    
    if (position.y <= changePosition && change) {
        setChange(false);
     }

     let headerTitle = {
        opacity: change ? "0" : "1",
        transition: "400ms ease"
    }

    return (
        <div className="threetheorem-hero">
            <div className="hero-content">
                <motion.div
                    initial={{opacity: 0, letterSpacing: 5, y: -100}}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeOut", duration: 0.8 }}
                    >
                <h1 style={headerTitle}>threeTheorem</h1>
                </motion.div>
                <motion.div
                initial={{opacity: 0, y: -50}}
                animate={{ opacity: 1, y: 0 }}
                transition={{ ease: "easeOut", duration: 1, delay: 0.5 }}
                >
                <h4>We build modern business-ready apps, features, integrations, and more for you and your business.</h4>
                </motion.div>
                <motion.div
                initial={{opacity: 0, y: -50}}
                animate={{ opacity: 1, y: 0 }}
                transition={{ ease: "easeOut", duration: 1, delay: 1 }}
                >
                <Link to="/get-started">
                <Button>
                    <p>Get Started</p>
                </Button>
                </Link>
                </motion.div>
           </div>

           <style jsx ="">
            {
              `
              .threetheorem-hero {
                  height: 100vh;
                  background:linear-gradient(
                    rgba(0, 0, 0, 1), rgba(33, 33, 33, 0.5)), url(${NYC}) center center no-repeat;
                  background-size: cover;
                  background-attachment: fixed;
                  z-index: 10;
                  
              }
              .three-d {
                  position: absolute;
                  left: 20%;
                  
              }
              .hero-content {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: white;
                text-align: center;
              }

              .hero-content button {
                border: 0px;
                transition: 0.3s;
                z-index: 1 !important;
                
              }
              .hero-content button:hover {
                box-shadow: 0 20px 50px -14px rgba(0,0,0,0.75);
                padding: 8px 70px;
              }

              @media screen and (max-width: 600px) {
                .threetheorem-hero {
                    height: 100vh;
                }
                .hero-content {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    text-align: center;
                  }

                .threetheorem-hero h1 {
                    opacity: 1 !important;
                }
              }
              `  
            }
           </style>
        </div>
    );
};

export default Hero;