import React from 'react';
import MobileWeb from '../images/mobileweb.svg'
import Fade from 'react-reveal/Fade';
import Neon from '../images/neon-buildings.png'
import NYBorder from './NYBorder'

const MainSectionThree = () => {
    return (
        <div className="main-section-3">
        <NYBorder/>
        <div className="main-section-3-content">
        {/* <i className="fas fa-mobile-alt"></i>  <i className="fas fa-desktop"></i> */}
        <Fade down>
        <img className="icon" src={MobileWeb} alt="mobile web icon" />
        </Fade>
        <Fade left>
            <h3>What do you want to build today?</h3>
        </Fade>
        <Fade right>
            <p>
                threeTheorem provides infrastructure, implementation, maintenance, operations, monitoring, and security for web and mobile applications.
            </p>
        </Fade>
            </div>
        <style jsx="">
        {
            `
            .main-section-3 {
                background:linear-gradient(
                    to right, rgba(33, 33, 33, 0.6), rgba(33, 33, 33, 0.6)), url(${Neon}) center center no-repeat;
                background-size: cover;
                background-attachment: fixed;
                height: 50vh;
                position: relative;
                bottom: 0;
                padding: 100px 0;
                
            }
            .main-section-3-content {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: white;
                text-align: center;
            }
            .main-section-3-content h3 {
                font-size: 40px;
                
            }
            .main-section-3-content p {
                font-size: 24px;
                text-align: left;
            }
            .icon {
                width: 140px;
                height: auto;
            }
            @media screen and (max-width: 600px) {
                .main-section-3 {
                    height: 60vh;
                    width: 100vw;
                    float: left;
                    background:linear-gradient(
                        to right, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7)), url(${Neon}) center center no-repeat;
                        background-attachment: fixed;
                        
                }
                .main-section-3-content {
                   
                    width: 100%;
                    position: relative;
                    top: 46%;
                    left: 50%;
                    color: white;
                    text-align: center;
                }
                .main-section-3 h3 {
                    font-size: 24px;
                    text-align: center;
                }
                .main-section-3 p {
                    text-align: center;
                }
            }
            `
        }
        </style>  
        </div>
    );
};

export default MainSectionThree;