import React, { useEffect } from 'react';
import PricingContent from '../components/PricingContent'

const Pricing = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div>
            <PricingContent/>
        </div>
    );
};

export default Pricing;