import React from 'react';
import Fade from 'react-reveal/Fade';
import NYC from '../images/nyc.png'

const AboutContent = () => {
    return (
        <>
        <div className="about-hero">
            <div className="about-hero-title">
                <Fade down>
                <h1>About Us</h1>
                </Fade>
            </div>
            
        </div>
        <div className="about-content">
        <Fade left>
            <div className="left-section mobile">
                <div className="content">
                    <h3>Welcome to 
                        <br/>
                        threeTheorem</h3>
                </div>
            </div>
            </Fade>
            <Fade right>
            <div className="right-section mobile">
                <div className="content">
                   <p>threeTheorem makes it easy to turn ideas into optimized web and mobile applications for any business. Our team of engineers work with stakeholders and business owners to understand business needs and how to curate our own library of components to construct to needed cloud based platforms.
                    </p>
                   
                    <p>
threeTheorem handles the end to end testing from the moment of idea conception to the production release. Our team of engineers handles the architecture, security, implementation and crash logs to provide depth of detail for all projects.
         </p>      
                </div>
            </div>
            </Fade>
        <style jsx>
        {
            `
            .about-hero {
                height: 60vh;
                background:linear-gradient(
                    rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7)), url(${NYC}) center center no-repeat;
                  background-size: cover;
                  
            }
            .about-hero-title {
                position: absolute;
                left: 50%;
                top: 30vh;
                transform: translate(-50%, -50%);
                color: white;
                text-align: center;
                width: 100%;
            }
            .about-content {
                position: relative;
                height: 50vh;
                background: -webkit-linear-gradient(to right, #7700FF, #4400FF);
                background: linear-gradient(to right, #7700FF, #4400FF);
                color: white;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                z-index: 0;
            }
            .left-section {
                width: 30vw;
                text-align: center;
                float: left;
                border-top-right-radius: 60%;
                border-bottom-right-radius: 60%;
                border-right: 40px solid #212121;
                height: 50vh;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                background: white;
                color: #212121 !important;
                // box-shadow: 0 20px 50px 10px rgba(0,0,0,0.5);
                
            }
            .left-section .content h3 {
               margin: 0;
               font-size: 40px;
            }
            .right-section {
                width: 70vw;
                float: right;
                text-align: center;
                padding: 0 100px;
            }
            .right-section .content p {
                font-size: 24px;
                padding-right: 50px;
            }
            .right-section .content .owner-name {
                color: #39e1ff;
                font-weight: bold;
            }
            @media screen and (max-width: 600px) {
                .about-content {
                    float: left;
                    height: auto;
                    display: block;
                    padding: 50px 0 60px;
                }
                .left-section {
                    width: 100%;
                    float: none;
                    position: relative;
                    display: block;
                    height: auto;
                    background: none;
                    color: white !important;
                    border-color: white;
                    box-shadow: none;
                    border-radius: 0%;
                    border: none;
                }
                .left-section .content h3 {
                    padding-top: 30px;
                }
                .right-section {
                    position: relative;
                    width: 90%;
                    float: none;
                    display: block;
                    margin-left: 11%;
                    padding: 0;

                }
            }
            `
        }
        </style>
        </div>
        </>
    );
};

export default AboutContent;