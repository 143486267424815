import React, { useEffect } from 'react';

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div>
            <h1>Blog</h1>
        </div>
    );
};

export default Blog;