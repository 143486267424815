import React from 'react';
import Web from '../images/web-dev.png'
import Button from '../components/Button'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'

const MainSectionTwo = () => {
    return (
        <div className="main-section-two">
            
                <div className="about-img">
                    <div className="inner-about">
                    </div>
                </div>
          
            <div className="about">
            <div className="inner-about">
            <Fade down>
            <h3>A simple approach to save 
                <br/>
                you time and money.</h3>
            </Fade>
            <Fade right>
            <p>
            Revamping your application and digital marketing doesn’t need to involve lengthy timelines, endless meetings and surprise scope creep. 
            <br/><br/>
            We’ve boiled it down to a simple, two-phased process proven to yield results.
            </p>
            </Fade>
            <Fade up>
            <Link to="/get-started"><Button><p>Get Started</p></Button></Link>
            </Fade>
          
            </div>
            </div> 
            
           <style jsx="">{
            `
            .main-section-two {
               font-size: 20px;
               color: white;
               position: relative;
               min-height: 600px;
              
            }

            .main-section-two .about {
                width: 50vw;
               float: right;
                text-align: center;
               color: #212121;
                min-height: 600px;
                padding-top: 80px;
                left: -2%;
                position: relative;
                top: -90px;
               
            }
            .main-section-two .about button:hover {
                box-shadow: 0 20px 50px -14px rgba(0,0,0,0.75);
                padding: 8px 70px;
              }
           
            .main-section-two .about-img {
               float: left;
                position: absolute;
                width: 50vw;
                left: -5%;
                background: url(${Web}) center center no-repeat;
                
                min-height: 650px;
                border-bottom-right-radius: 70%;
                border-right: 40px solid #212121;
                
            }
            .main-section-two inner-about {
                margin: 0 auto;
                color: #212121;
            }
      
            .main-section-two .inner-about h3 {
                font-size: 40px;
                text-align: center;
            }

            @media screen and (max-width: 600px) {
                .main-section-two .about {
                    width: 100vw;
                    // float; none;
                    height: auto;
                    min-height: 450px;
                    color: white;
                   top: 0px;
                }
                .main-section-two .about-img {
                    width: 100vw;
                    height: auto;
                    position: relative;
                    left: 0;
                    top: 0px;
                    float: left;
                    background: url(${Web}) center center no-repeat;
                    background-size: cover;
                    min-height: 417px;
                    box-shadow: none;
                    border-bottom-right-radius: 0%;
                    border-bottom-left-radius: 0%;
                    border-right: 100px solid #212121;
                }
            
                .main-section-two .inner-about {
                    padding: 0px 20px 100px;
                    width: 80%;
                    margin: 0 auto;
                   
                }
                .main-section-two .inner-about h3 {
                    font-size: 24px;
                    text-align: center;
                }
            }   
            `
           }
           </style>
        </div>
    );
};

export default MainSectionTwo;