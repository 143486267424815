import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import Logo from '../../images/3t-white.png'
import { Link } from 'react-router-dom'

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const variants2 = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const Navigation = () => (
  <motion.ul variants={variants}>
  <Link to="/">
    <motion.li className="menu-logo" variants={variants2}>
      <img className="logo" src={Logo} alt="logo" /> <span>threeTheorem</span>
      <style jsx ="">
      {
        `
        .menu-logo {
         
          padding: 12px 0;
        }
        .menu-logo span {
          padding-left: 12px;
          text-transform: lowercase;
          font-size: 24px;
        }
        .logo {
          width: 70px;
          height: auto;
          transition: 0.3s;
        }
        `
      }
      </style>
    </motion.li>
    </Link>
    {itemIds.map(i => (
      <MenuItem i={i} key={i} />
    ))}
  </motion.ul>
);

const itemIds = [0, 1, 2, 3, 4];
