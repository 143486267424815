import React, { useEffect } from 'react';
import ContactContent from '../components/ContactContent'

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <ContactContent/>
        </div>
    );
};

export default Contact;