import * as React from "react";
import { useEffect, useRef } from "react";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(20px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    }
  }
};

export const NavMobile = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const height = 400;

  const handleClickOutside = () => {
    toggleOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);

    } else {
      document.removeEventListener("mousedown", handleClickOutside);
     
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    
    };
  });

  return (
    <div>
      <div onClick={() => toggleOpen()} className="menu-bg">
        <span className="menu-button"></span>
      </div>
       
      <AnimatePresence>
        {isOpen && (
          <motion.nav
            initial={"closed"}
            exit={"closed"}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            // onAnimationComplete={() => console.log("end")}
            ref={containerRef}
            variants={{}}
          >
            <motion.div className="background" variants={sidebar} />
            <MenuToggle toggle={() => toggleOpen()} />
            <Navigation/>

          </motion.nav>
        )}
      </AnimatePresence>   
      <style jsx="">
      {
        `
      .menu-bg {
        position: fixed;
        background: black !important;
        width: 55px;
        height: 55px;
        margin: 10px 12px;
        z-index:999;
        border-radius: 50%;
        display: none;
      }
      .menu-button {
          width: 15px;
          height: 3px;
          background: #fff;
          margin: 20px 0 0 20px;
          position: fixed;
          z-index: 999;
          position: fixed;
          top: 16px;
          left: 16px;
          box-shadow: 0 20px 50px 40px rgba(0,0,0,0.1);
          -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph; /*IE*/
    filter: fliph; /*IE*/
        
      }

      .menu-button:before {
          display: block;
          content: "";
          width: 23px;
          height: 3px;
          box-shadow: 0 -8px 0 0 #fff, 0 8px 0 0 #fff;
      }
        nav {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          width: 70%;
          z-index: 999;
          display: none;
          text-transform: uppercase;
          font-weight: bold;
        }
        .mobile {
          z-index: 999;
        }
        .background {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          width: 70%;
          background: linear-gradient(
            rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.9));
            background-repeat: no-repeat;
            background-position: center bottom;
           
            box-shadow: 0 20px 50px 60px rgba(0,0,0,0.7);
        }
          
          .mobile-button {
            outline: none;
            border: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            cursor: pointer;
            position: fixed;
            top: 9px;
            left: 10px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: black;
            z-index: 999;
            display: none;
          }
          
          nav ul {
            padding: 15px 0;
            position: absolute;
            top: 0;
            margin: 0 auto;
            width: 100%;
            z-index: 997;
          }
          nav li {
            list-style: none;
            margin: 0 auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-align: center;
            color: white;
            width: 80%;
          }
          
          .icon-placeholder {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            flex: 40px 0;
            margin-right: 20px;
          }
          
          .text-placeholder {
            border-radius: 5px;
            width: 200px;
            height: 20px;
            flex: 1;
            padding: 10px 0 13px;
          }
          .text-placeholder a {
            color: #fff;
          }
          
          @media screen and (max-width: 600px) {
            nav {
              display: block;
            }
            .menu-bg {
              display: block;
            }
          }
        `
      }
      </style>
    </div>
  );
};