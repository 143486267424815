import React from 'react';
import Logo from '../images/3t-white.png'
import Button from './Button'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-top">
                <h4>
                    Connect with threeTheorem to turn ideas and specs into working code. 
                </h4>
                <br/>
                <div className="contact">
                    {/* <span>info@threetheorem.com</span> */}
                    <span><Link to="/get-started"><Button><p>Connect</p></Button></Link></span>
                    
                </div>
            </div>
            <div className="footer-info">
                <div>
                    {/* <i class="fas fa-map-marker-alt"></i> */}
                    <br/>
                    <div className="address">
                    320 West 38th Street
                    <br/>
                    New York, New York 10018
                    </div>
                </div>
                <div>
                    {/* <i class="fas fa-envelope"></i> */}
                    <div className="email">
                    
                    info@threetheorem.com
                    </div>
                </div>
            </div>
            <div className="footer-middle">
                <Link to="/">
                <img className="logo" src={Logo} alt="logo" /> <span> threeTheorem</span>
                </Link>
            </div>
            <div className="footer-menu">
                <Link to="/careers">
                 Join Our Team
                </Link>
                |
                <Link to="/policy">
                    Privacy Policy
                </Link>
                |
                <Link to="/contact">
                    Contact Us
                </Link>
            </div>
            <div className="footer-bottom">
            <Link to="/">
                threeTheorem © 2020
            </Link>
            </div>
            <style jsx="">
            {
            `
             .footer {
                 padding: 100px 100px 10px;
                 background: #212121;
                 color: #fff;
                
             }
             .footer .footer-info {
                 padding: 50px 0 20px;
                 margin: auto;
                 width: 50%;
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 font-size: 24px;
             
             }
             .footer .footer-info i {
                 font-size: 40px;
                
             }
             
             .footer .footer-middle {
                 margin: 50px 0 30px;
                 text-align: center;
                 font-size: 30px;
                 font-weight: bold;
             }
             .footer .footer-middle span {
                position: relative;
                top: -28px;
                font-size: 30px;
                padding-left: 5px;
                text-transform: none;
            }
          
           
             .footer .footer-middle .logo {
                 width: 100px;
                 height: auto;
                 
             }
             .footer .footer-top {
                 background: white;
                 color: #212121;
                 padding: 50px;
                 border-radius: 20px;
                 margin: auto;
             }  
             .footer .footer-top {
               display: flex;
               justify-content: space-between;
               align-items: center;
             }
             .footer .footer-top h4 {
                margin: 0 !important;
                padding: 0 !important;
                text-align: center;
            }   
            .footer .footer-bottom {
                margin-top: 10px;
                border-top: 1px solid rgb(255, 255, 255, 0.1);
                font-size: 20px;
                text-align: right;
            } 
            .footer .footer-bottom a {
                text-transform: none;
                font-size: 17px;
                // font-weight: bold;
                
            }
            .footer-menu a {
                font-size: 17px;
                // font-weight: bold;
                padding: 0 10px;
                text-transform: none;
                transition: ease .3s;
            }
            .footer-menu a:hover {
                color: #39e1ff;
                
            }
            @media screen and (max-width: 1000px) {
                .footer .footer-info {
                    padding: 50px 0 20px;
                    width: 80%;
                }
              
            }
            @media screen and (max-width: 600px) {
                .footer {
                    padding: 0;
                    
                }
                .footer .footer-info {
                    display: block;
                    text-align: center;
                    padding-bottom: 0;
                }
                .footer .footer-info .email{
                    margin-top: 50px;
                }
                .footer .footer-top {
                    border-radius: 10px;
                    display: inline-block;
                    padding: 50px 10px;
                    
                    margin: 70px 20px 0;
                }
                .footer .footer-top h4 {
                    font-size: 20px;
                    
                }
                .footer .footer-middle .logo {
                    width: 80px;
                    height: auto;
                    
                }
                .footer .footer-middle span {
                    top: -22px;
                    
                }

                .footer .footer-bottom {
                    text-align: center;
                    padding: 6px 0;
                    font-size: 18px;
                }
                .footer-menu {
                    text-align: center;
                }
            }
            `
            }
            </style>
            
        </div>
    );
};

export default Footer;