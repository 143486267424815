import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
// import Work from '../pages/Work'
import Pricing from "../pages/Pricing"
import Contact from "../pages/Contact"
import Blog from "../pages/Blog"
import GetStarted from "../pages/GetStarted"
import Success from "../pages/Success"
import NotFound from "../pages/NotFound"
import Careers from "../pages/Careers"
import Policy from "../pages/Policy"

const routePaths = [
  { path: "/", name: "Home", Component: Home },
  { path: "/about", name: "About-Us", Component: About },
  // {path: '/work', name: 'Our Work', Component: Work},
  { path: "/pricing", name: "Pricing", Component: Pricing },
  { path: "/blog", name: "Blog", Component: Blog },
  { path: "/contact", name: "Contact", Component: Contact },
  { path: "/get-started", name: "Get-Started", Component: GetStarted },
  { path: "/success", name: "Success", Component: Success },
  { path: "/careers", name: "Careers", Component: Careers },
  { path: "/policy", name: "Privacy-Policy", Component: Policy }
];

const routes = () => {
  return (
    <div>
        <Switch>
      {routePaths.map(({ path, name, Component }) => (
        
        <Route exact path={path} key={name} name={name}>
          <div className="page">
            <Component />
          </div>
        </Route>
        
      ))}
      <Route component={NotFound} />
      </Switch>
    </div>
    
  );
};

export default routes;
