import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";

const NotFound = () => {
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className="not-found">
            <h4>Page not found!</h4>
            <button onClick={() => history.goBack()}><p>Go Back</p></button>
          <style js=""x>
          {
              `
                .not-found {
                    background: #212121;
                    padding: 0px 20px;
                    height: 350px;
                }
                .not-found h4 {
                    position: relative;
                    padding-top: 200px;
                    color: white;
                    text-align: center;
                    margin: 0;
                    padding-bottom: 20px;
                }
              `
          }
          </style>
        </div>
    );
};

export default NotFound;