import React from 'react';
import NY from '../images/white-ny.png'

const NYBorder = () => {
    return (
        <div className="ny">
            <img className="ny-border" src={NY} alt="ny border" />
            <style jsx>
            {
                `
                .ny {
                    overflow-x: hidden !important;
                }
                .ny-border {
                    position: absolute;
                    bottom: 0px;
                    width: 100vw;
                    z-index: 0;
                    opacity: 0.02;
                }
                `
            }
            </style>
        </div>
    );
};

export default NYBorder;