import React from 'react';
import Mobile from '../images/mobile-app.png'
import Button from '../components/Button'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'

const MainSection = () => {
    
    return (
        <div className="main-section">

            <div className="about">
            <div className="inner-about">
            <Fade down>
            <h3>Expert teams build it for you.</h3>
            </Fade>
            <Fade left>
            <p>
            Work with expert software developers, designers, and product managers.
            <br/><br/>
            Track your product development in real-time with daily builds.
            <br/><br/>
            Compare the cost of new features before you build.
            <br/><br/>
            Use top product development tools such as Slack, Trello, JIRA, and Github.
            <br/><br/>
            Ship fast and scale quickly using the threetheorem software platform. ‍
            <br/><br/>
            threeTheorem hosts, maintains, updates, and scales your product as needed.
            </p>
            </Fade>
            <Fade up>
            <Link to="/about"><Button><p>Learn More</p></Button></Link>
            </Fade>
            </div>
            </div>     
        <div className="about-img">
        <div className="inner-about">
       
            </div>
        </div>
       
           <style jsx ="">{
            `
            .main-section {
               font-size: 20px;
               color: white;
               position: relative;
               min-height: 600px;
               z-index: 99;
              
            }

            .main-section .about {
                width: 50vw;
               float: left;
               color: #212121;
                min-height: 600px;
                text-align: center;
               
        
            }
            .main-section .about button {
               z-index: 1;
                position: relative;
                margin: 0 auto;
            }
            .main-section .about button:hover {
                box-shadow: 0 20px 50px -14px rgba(0,0,0,0.75);
                padding: 8px 70px;
              }

            .main-section .about-img {
               float: right;
               position: absolute;
                width: 50vw;
                left: 45%;
                background: url(${Mobile}) center bottom no-repeat;
                background-size: cover;
                min-height: 600px;
                border-top-left-radius: 70%;
                border-left: 40px solid #212121;
            }
            .main-section .inner-about {
                padding: 50px;
                width: 70%;
                margin: 0 auto;
            }
            .main-section .inner-about h3 {
                font-size: 40px;
                text-align: center;
            }

            @media screen and (max-width: 600px) {
                .main-section .about {
                    width: 100vw;
                    
                    height: auto;
                    position: relative;
                    display: flex;
                }
                .main-section .about-img {
                    width: 100vw;
                    height: auto;
                    position: relative;
                    left: 0px;
                   display: flex;
                    background: url(${Mobile}) center center no-repeat;
                    background-size: cover;
                    min-height: 350px;
                    box-shadow: none;
                }
            
                .main-section .inner-about {
                    padding: 50px 20px 0;
                    width: 80%;
                    margin: 0 auto;
                   
                }
                .main-section .inner-about h3 {
                    font-size: 24px;
                    text-align: center;
                }
            }   
            `
           }
           </style>
        </div>
    );
};

export default MainSection;