import React from "react";
import Fade from "react-reveal/Fade";
import Button from "./Button";
import threeD from "../images/d.png";
import NYC from '../images/nyc.png'

const ContactContent = () => {
  return (
    <div>
      <div className="contact-hero"> 
        
        <div className="contact-hero-title">
          
          <Fade down>
            <h1>Contact Us</h1>
          </Fade>
        </div>
      </div>

      <div className="contact-info">
        <Fade left>
          <div className="left-section mobile">
            <div className="content">
              <h3>Connect with us</h3>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="right-section mobile">
            <div className="content">
              <p>
                For additional information about how we can grow your business
                with the help of our team or to request a free quote, please use
                the contact form below and we will get to you as soon as
                possible.
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <div className="contact-content">
        <Fade left>
          <div className="right-section mobile">
            <div className="form content">
              <form
                name="contact"
                method="POST"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                netlify="true"
              >
                <input type="hidden" name="form-name" value="contact" />

                <Fade left>
                  <label>
                    <input
                      className="name"
                      name="name"
                      type="text"
                      placeholder="Name*"
                      required
                    />
                  </label>
                  <br />
                  <br />
                  <label>
                    <input
                      className="email"
                      name="email"
                      type="text"
                      placeholder="Email*"
                      required
                    />
                  </label>
                  <br />
                  <br />
                  <label>
                    <input
                      className="company"
                      type="text"
                      name="company"
                      placeholder="Company"
                    />
                  </label>
                  <br />
                  <br />
                  <label>
                    <input
                      className="subject"
                      type="text"
                      name="subject"
                      placeholder="Subject*"
                      required
                    />
                  </label>
                  <br />
                  <br />
                  <label>
                    <textarea
                      className="message"
                      name="message"
                      placeholder="Message*"
                      required
                    ></textarea>
                  </label>
                </Fade>
                <br />
                <br />
                <Fade right>
                  <Button type="submit">
                    <p className="submit">Submit</p>
                  </Button>
                </Fade>
              </form>
            </div>
          </div>
        </Fade>
      </div>
      <style jsx>
          {`
            .contact-hero {
                height: 60vh;
                background:linear-gradient(
                    rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7)), url(${NYC}) center center no-repeat;
                  background-size: cover;
                  
            }
            .contact-hero-title {
                position: absolute;
                left: 50%;
                top: 30vh;
                transform: translate(-50%, -50%);
                color: white;
                text-align: center;
                width: 100%;
            }
         
            .contact-content {
                position: relative;
                background:linear-gradient(
                    to right, rgba(119, 0, 255, 0.9), rgba(68, 0, 255, 1)), url('${threeD}') center center no-repeat;
                background-size: cover;
                background-attachment: fixed;
                color: white;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                z-index: 0;
                padding: 70px 0;
                z-index: 99;
            }
            .form button {
               float: left !important;
               width: 40%;
               background: transparent;
               border: 3px solid white;
            }
            .right-section {
                width: 50%;
              
            }
            .right-section .submit {
                padding-right: 0 !important;
                font-size: 16px !important;
            }
            .right-section .content .owner-name {
                color: #39e1ff;
                font-weight: bold;
            }
     
            .right-section .form .name {
                width: 100%;
                height: 35px;
                background: transparent;
                border-radius: 10px;
                border: 3px solid white;
                padding: 0 10px;
                font-size: 16px;
                color: white;
                box-shadow: 0 20px 30px -14px rgba(0,0,0,0.5);
                font-family: 'Open Sans Condensed', sans-serif;
            }
            .right-section .form .name:focus {
                outline: none;
            }
            
            .right-section .form .email,
            .right-section .form .company,
            .right-section .form .subject {
                background: transparent;
                border-radius: 10px;
                border: 3px solid white;
                padding: 0 10px;
                font-size: 16px;
                color: white;
                box-shadow: 0 20px 30px -14px rgba(0,0,0,0.5);
                width: 100%;
                height: 35px;
                font-family: 'Open Sans Condensed', sans-serif;
            }
            .right-section .form .email:focus,
            .right-section .form .company:focus,
            .right-section .form .subject:focus {
                outline: none;
            }
            .message {
                width: 100%;
                background: transparent;
                border: 3px solid white;
                border-radius: 10px;
                padding: 10px 10px;
                min-height: 120px;
                font-size: 16px;
                color: white;
                font-family: 'Open Sans Condensed', sans-serif;
            }
            .message:focus {
                outline: none;
            }
            .right-section ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #fff;
            }
            .right-section ::-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #fff;
              }
              
            .right-section ::-ms-input-placeholder { /* Microsoft Edge */
                color: #fff;
              }


            @media screen and (max-width: 600px) {
                .contact-content {
                    float: left;
                    height: auto;
                    display: block;
                    padding: 70px 0px;
                    width: 100%;
                    margin: 0 auto;
                }

                .right-section {
                    position: relative;
                    width: 80% !important;
                    float: none;
                    display: block;
                    margin: auto;
                    padding: 0;

                }
                .right-section .form .name,
                .right-section .form .email,
                .right-section .form .company,
                .right-section .form .subject,
                .right-section .form .message {
                    padding: 0 4px;
                    margin: 0 auto;
                    width: 95%;
            }
            .right-section .form .message {
                padding: 6px 4px;
            }

            .form button {
                
                width: 100%;
                
             }
            }

            `}
        </style>
        <style jsx="true">
          {`
            .contact-info {
                position: relative;
                height: 30vh;
                color: white;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                z-index: 0;
            }
            .left-section {
                width: 30vw;
                text-align: center;
                float: left;
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
                border-right: 40px solid #212121;
                height: 30vh;
                left: -50px;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
              
                color: #212121 !important;
                // box-shadow: 0 20px 50px 10px rgba(0,0,0,0.5);
              
            }
            .left-section .content h3 {
               margin: 0;
               font-size: 40px;
            }
            .right-section {
                width: 70vw;
                float: right;
                text-align: center;
                padding: 0 100px;
                color: #212121;
            }
            .right-section .content p {
                font-size: 24px;
                padding-right: 50px;
            }
            .right-section .content .owner-name {
                color: #39e1ff;
                font-weight: bold;
            }
            @media screen and (max-width: 600px) {
                .contact-info {
                    float: left;
                    height: auto;
                    display: block;
                    padding: 0px;
                    width: 100%;
                    background: transparent;
                    color: white;
                    padding: 40px 0;
                }
                .left-section {
                    width: 100%;
                    float: none;
                    left: 0;
                    position: relative;
                    display: block;
                    height: auto;
                    background: transparent
                    color: #fff !important;
                    border-color: white;
                    box-shadow: none;
                    border-radius: 0%;
                    border: none;
                }
                .left-section .content h3 {
                    padding-top: 30px;
                    color: #fff;
                }
                .right-section {
                    position: relative;
                    width: 100%;
                    float: none;
                    display: block;
                    margin: auto;
                    padding: 0 !important;
                    background: transparent;
                    color: #fff;
                }
                .right-section .content p {
                    padding: 0;
                }
            }
            `}
        </style>
    </div>
  );
};

export default ContactContent;
